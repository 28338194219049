// this is now in common
@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif;
}

.page {
  position: relative;
  padding-top: 100px;
  padding-bottom: 150px;
  max-width: 1080px;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.shrink {
    height: 0;
  }
  &.login {
    max-width: 100vw;
  }
}

.icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.icon-spins {
  background-image: url('../img/i-spins.svg');
}
.icon-angle {
  background-image: url('../img/i-angle.svg');
}
.icon.eval {
  background-image: url('../img/i-eval.svg');
}
.icon.star {
  background-image: url('../img/star.svg');
}
.icon.trophy {
  background-image: url('../img/trophy.svg');
}
.icon.logout {
  background-image: url('../img/logout.svg');
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.icon.calendar {
  background-image: url('../img/i-calendar.svg');
  height: 25px;
  width: 25px;
}
.icon.bell {
  background-image: url('../img/bell.svg');
  width: 25px;
  height: 25px;
}
.caret {
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #c8c8c8;
  transform: rotate(90deg);
  margin-left: -10px;
  margin-right: 10px;
  transition: transform .4s;
  &.down {
    transform: rotate(180deg);
  }
  @media (max-width: 767px) {
    margin-left: 0px;
  }
}
// this is now in common
.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px);
  .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
}

.icon.listMenu {
  background-image: url('../img/listMenu.svg');
  height: 25px;
  width: 25px;
  @media (max-width: 767px) {
    height: 20px;
    width: 20px;
  }
}

.icon.edit {
  background-image: url('../img/i-edit.svg');
  height: 25px;
  width: 25px;
}

.icon.remove {
  background-image: url('../img/i-delete.svg');
  height: 25px;
  width: 25px;
}

.icon.note {
  background-image: url('../img/i-note.svg');
}

.icon.assessment {
  padding: 0;
  background-image: url('../img/i-assessment.svg');
}

.icon.questionnaire {
  margin: 0;
  background-image: url('../img/i-questionnaire.svg');
}

.icon.ride {
  background-image: url('../img/i-ride.svg');
}

.icon.add {
  width: 20px;
  height: 20px;
  background-image: url('../img/i-add.svg');
}

h2 {
  font-size: 24px;
  text-align: center;
  font-weight: normal;
  margin: 20px 0;
}

h3 {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: -10px 0 0;
  font-size: 14px;
}

.appBar {
  box-shadow: none !important;
  height: 100px;
}

.flexRow {
  display: flex;
  justify-content: center;
  &.column {
    flex-direction: column;
  }
}

.inputSearch {
  background-image: url('../img/search.svg');
  background-size: 20px;
  background-position: center left 5px;
  background-repeat: no-repeat;
  padding: 0px 10px 0px 35px !important;
  line-height: 2;
  border-bottom: 1px solid #777;
  input {
    padding: 5px;
    font-size: 24px;
  }
}

.direction {
  background-image: url('../img/rotation.svg');
  width: 35px;
  height: 35px;
  margin: 0 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: transform 0.3s;
  display: block;
  cursor: pointer;
  &.white {
    background-image: url('../img/rotation-white.svg');
  }
  &.ccw {
    transform: scaleX(-1);
  }
}

.button {
  border-radius: 50px !important;
  text-transform: none !important;
  width: 200px;
  box-shadow: none !important;
  font-weight: bold !important;
  line-height: 18px;
  padding: 2px;
  height: 50px;
  min-width: 40px !important;
  &:disabled {
    opacity: .5;
  }
  span {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    width: 150px;
    font-size: 18px !important;
  }
}

.graphWrapper {
  border-radius: 15px;
  height: 200px;
  padding: 20px 5px 10px;
  margin: 20px auto;
  cursor: pointer;
  @media (max-width: 767px) {
    height: 150px;
  }
  rect[height="180"] {
    width: 10px;
    transform: translateX(15px);
  }
  line {
    stroke: rgba(250,250,250,.1) !important;
  }
  text {
    fill: #fff !important;
  }
  table {
    margin: 0px -5px;
    width: 120px !important;
  }
}

.listExpandable {
  margin: 20px auto;
  padding-left: 0;
  @media (max-width: 1024px) {
    margin: 20px -20px;
  }

  .evaluationConfig {
    .angleSelectWrapper {
      .angleSelect {
        width: 80px;
        margin-left: 45px;
      }
      .icon-angle {
        top: 2px;
        margin-right: 10px;
      }
    }
    .durations {
      margin-top: -20px;
      padding: 0px 10px;
      .durationResult {
        display: flex;
        max-width: 225px;
        font-size: 24px;
        text-align: left;
        align-items: center;
        .durationInput {
          position: relative;
          left: 13px;
          font-size: 24px;
          font-family: 'Cairo';
          max-width: 50px;
          text-align: center;
          padding: 0px 10px;
          margin: 5px 0;
          display: block;
          box-sizing: content-box;

          border-radius: 6px;
          border-style: none;
          border: 1px solid #c8c8c8;
        }
        .name {
          flex: 1;
          text-transform: capitalize;
          padding-left: 10px;
          font-size: 18px;
        }
        &.right .icon {
          transform: rotate(90deg);
        }
        &.forward .icon {
          transform: rotate(180deg);
        }
        &.left .icon {
          transform: rotate(270deg);
        }
        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .sessionTotals {
    margin-right: 25px;
    margin-left: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    @media (max-width: 805px) {
      margin-right: 50px;
    }
    &.eval {
      width: 120px;
      display: none;
      @media (min-width: 660px) {
        display: block;
      }
      @media (max-width: 805px) {
        width: 100px;
        span {
          margin-left: 0px;
        }
      }
    }
    &.spins {
      width: 120px;
      @media (max-width: 805px) {
        width: 100px;
      }
    }
    em {
      font-size: 12px;
      margin-left: 40px;
      font-weight: bold;
      font-style: normal;
      @media (max-width: 1100px) {
        display: none;
      }

    }
    .icon {
      width: 15px;
      height: 15px;
    }
    .icon-angle, .icon-spins {
      display: none;
    }
    @media (max-width: 660px) {
      &.spins, &.angle {
        display: none;
      }
      .total {
        margin-left: 5px !important;
        font-size: 14px !important;
      }
    }
    @media (max-width: 915px) {
      &.coreScore {
        margin-left: -15px;
      }
      .icon {
        display: block;
      }
      .label {
        display: none;
      }
    }
    .total {
      margin-left: 10px;
      width: 20px;
      font-weight: bold;
      font-size: 24px;
    }
  }

}

.expandableItem {
  list-style-type: none;
  border-top: 1px solid #c8c8c8;
  padding: 3px 0;

  .locationSelectWrapper.session {
    pointer-events: none;
    position: relative;
    padding-left: 5px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    &:before {
      margin-right: -15px;
      z-index: 5;
      position: relative;
      display: block;
      background-image: url('../img/location.svg');
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      content: '';
      opacity: .2;
    }
    svg {
      opacity: 0;
    }
    &.editing {
      pointer-events: all;
      svg {
        opacity: 1;
      }
    }
  }
  
  strong {
    font-weight: normal;
    position: relative;
    top: -1px;
  }
  .deleteModal {
    flex-direction: column;
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: -3px;
    background: rgba(250,250,250,0.95);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    footer {
      margin-top: 15px;
      button {
        margin: 0 20px;
      }
    }
  }
  .angle {
    text-align: right;
    .label {
      width: 100px;
    }
  }
  p span {
    font-size: 12px;
    display: inline-block;
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 10px;
    }
  }

  footer {
    justify-content: flex-start;
    button {
      margin: 20px 20px 20px 0;
    }
  }

  &.noteItem {
    p {
      display: flex;
    }
    strong {
      width: 180px;      
    }
    .noteInput {
      width: 100%;
      max-width: 100%;
      margin: -30px 0 0;
    }

    .note.editing {
      padding-top: 25px;      
    }

  }

  &.assessmentItem {

    p {
      display: flex;
    }

    strong {
      width: 180px;      
    }

    .donutChart {
      &:after {
        background-color: white;
      }
    }

    .assessmentResults {
      margin-left: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: right;
      position: relative;
      transition: .25s;
      top: 0;
      opacity: 1;
      &.expanded {
        opacity: 0;
        top: -30px;
      }
      @media (max-width: 705px) {
        display: none;
      }
    }

    .flexRow.assessment {
      justify-content: flex-start;
      max-width: 100%;
      margin-top: -20px;
      margin-left: -35px;
      opacity: .75;
      pointer-events: none;
      @media (max-width: 900px) {
        margin-left: 0px;
      }
      &.editing {
        opacity: 1;
        pointer-events: all;
      }
    }

    .result {
      display: flex;
      align-items: center;
      flex-direction: row;
      text-transform: uppercase;
      @media (max-width: 900px) {
        flex-direction: column;
      }
      .resultValue {
        font-weight: bold;
        margin-left: 10px;
        font-size: 24px;
        position: relative;
        top: -1px;
        @media (max-width: 900px) {
          margin-left: 0;
          font-size: 14px;
        }
      }
    }
  }

  &.allcore360 {
    .rideConfig {
      border-top: 0px;
      padding: 0;
      margin: -20px 0 0;
      @media (max-width: 500px) {
        margin: -20px 0 20px;
      }
    }
    strong {
      display: block;
      font-weight: normal;
      font-size: 18px;
      width: 175px;
      @media (max-width: 500px) {
        font-size: 16px;
        width: 155px;
      }
    }
    @media (max-width: 500px) {
      .caret {
        margin-left: 10px;
        margin-right: 5px;
      }
    }

    li {
      justify-content: flex-start;
      padding: 0;
      margin-bottom: 20px;
      margin-bottom: 0px;
      .parameter {
        margin-right: 20px;
      }
      .delete {
        margin-left: 20px;
      }
      pointer-events: none;
      &.editing {
        pointer-events: all;
        input {
          border-radius: 6px;
          border: 1px solid #c8c8c8;
        }
      }
      .set {
        @media (max-width: 660px) {
          padding: 0;
        }
        animation: none;
        padding: 20px 0px;
        justify-content: flex-start;
        border: 0px;
        display: flex;
      }
    }
  }
  .listMenu {
    cursor: pointer;
    z-index: 5;
    padding: 10px;
    background-size: auto;
    position: absolute;
    right: 5px;
    top: 15px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    margin-right: -10px;
    @media (max-width: 767px) {
      right: 20px;
    }
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
  [role="button"] {
    padding-left: 20px;
    background-color: white;
  }
  .listActions {
    padding-right: 0;
    position: absolute;
    right: -120px;
    opacity: 0;
    top: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(.17,.89,.33,1.15);
    display: flex;
    align-items: center;
    height: 65px;
    pointer-events: none;
    .icon {
      padding: 10px;
      background-size: 60%;
      background-color: white;
      transition: background-color .25s;
      border-radius: 100%;
      cursor: pointer;
      background-color: white;
      transition: .3s;
      &:hover {
        background-color: #eee;
      }
    }
    &.active {
      pointer-events: all;
      right: 30px;
      opacity: 1;
      @media (max-width: 767px) {
        right: 50px;
      }
    }
    .icon {
      margin-right: 30px;
      @media (max-width: 767px) {
        margin-right: 0px;
      }
    }
  }
  &:last-child {
    border-bottom: 1px solid #c8c8c8;
  }
}

.tabBar {
  @media (max-width: 767px) {
    > div {
      height: 65px;
    }
    [role="tab"] {
      font-size: 14px;
      padding-top: 10px;
      max-width: 100px;
    }
  }
}


.questionnaireItem {
  .empty {
    opacity: .35;
  }
  strong {
    top: 2px;
  }

  .summaryItem {
    font-size: 12px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    @media (max-width: 660px) {
      display: none;
    }
    .label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
    }
    .percentage {
      display: block;
      position: relative;
      font-size: 14px;
      margin-left: 0;
      font-weight: normal;
      left: 1px;
    }
    span {
      font-size: 24px;
      font-weight: bold;
      position: relative;
      top: -1px;
      margin-left: 10px;   
    }
  }

  .questionnaireContent {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: -25px;
      .column {
        padding: 20px;
        margin-bottom: -20px;
      }
    }
    .column {
      flex: 1;
      &:first-child {
        padding-right: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
      margin-top: -35px;
      font-size: 14px;
      .questionItem {
        margin: 15px 0;
      }
      header {
        font-weight: bold;
        display: flex;
        align-items: center;
        span {
          display: block;
          font-weight: bold;
          font-size: 24px;
          margin-right: 10px;
        }
      }
      p {
        margin-top: -10px;
        line-height: 20px;      
      }
    }
  }

}

.loadingText {
  text-transform: none;
  padding-top: 15px;
}

.modal {
  animation: fadeIn .3s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .rideSummary {
    border-top: 0px;
  }
  p {
    font-size: 18px;
    max-width: 450px;
    text-align: center;
    margin-bottom: 50px;
    line-height: 22px;
    @media (max-width: 767px) {
      padding: 0 25px;
    }
  }
  footer {
    button {
      margin: 0 25px;
      @media (max-width: 767px) {
        margin: 0 10px;
      }
      &.secondary {
        background-color: rgba(0,0,0,0);
        color: white !important;
        border: 2px solid white !important;
      }
    }
  }
}

.form-group {
  &.disabled {
    pointer-events: none;
  }
}

footer.support {
  z-index: 10;
  position: fixed;
  text-align: center;
  font-size: 12px;
  color: #999;
  width: 100%;
  bottom: 8px;
  left: 0;
  right: 0;
  @media (max-width: 767px) {
    bottom: 3px;
    font-size: 10px;
  }
}

.announcementsModal {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1200;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  pointer-events: none;
  flex-direction: column;
  opacity: 0;
  transition: .4s;
  .react-swipeable-view-container {
    max-width: 767px;
  }
  .dismiss {
    background-color: rgba(250,250,250,.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    background-color: rgba(250,250,250,.8);    
  }
  .announcement {
    animation: bounceInUp .8s;
    box-shadow: 2px 5px 20px -10px rgba(0,0,0,0.75);
    padding: 20px;
    margin: 20px;
    max-width: 767px;
    min-height: 80px;
    color: white;
    border-radius: 6px;
    background-color: rgba(0,0,0,.85);
    display: flex;
    bottom: 0px;
    transition: bottom .4s;
    flex-direction: column;
    position: relative;
    a {
      color: rgba(119, 186, 62, 1);
    }
    header {
      font-size: 24px;
      margin-bottom: 10px;
    }
    footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;  
    }
    .description {
      p {
        line-height: 22px;
        margin: 15px auto;
      }
    }
  }

  .indicators {
    animation: bounceInUp .8s;
    display: flex;
    position: relative;
    z-index: 100;
    li {
      border: 1px solid rgba(0,0,0,.85);
      border-radius: 100%;
      width: 15px;
      height: 15px;
      margin: 5px;
      cursor: pointer;
      &.active {
        background-color: rgba(0,0,0, .85);
      }
    }
  }
}

.tooltip {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  header {
    width: 100px;
    line-height: 22px;
    margin-right: 10px;
  }
  &:hover {
    .tooltipText {
      bottom: 35px;
      pointer-events: all;
      opacity: 1;
    }
  }
  .icon {
    margin-right: 10px;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('../img/help.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .tooltipText {
    @media (max-width: 768px) {
      right: auto;
      left: 0;
    }
    background-color: white;
    border-radius: 6px;
    right: 0px;
    bottom: 15px;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    text-transform: initial;
    z-index: 5;
    width: 350px;
    padding: 10px;
    box-shadow: 2px 5px 20px -10px rgba(0,0,0,0.75);
  }    
}

.waiverText {
  text-align: center;
  background-color: #e8e8e8;
  padding: 5px 10px 25px;
  margin-top: 20px;
  border-radius: 6px;
p {
    margin: -10px 0 15px;
    line-height: 20px;
  }
  a {
    text-decoration: none;
  }
}

#zohohc-asap-web-launcherbox {
  border-radius: 50px;
  width: 35px;
  height: 35px;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
    bottom: 10px;
    right: 10px;
    svg {
      width: 16px;
      height: 16px;
      margin-top: 5px;
    }
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}