@import url(https://fonts.googleapis.com/css?family=Cairo:400,700&display=swap);
@-webkit-keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

body {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif; }

.page {
  position: relative;
  padding-top: 100px;
  padding-bottom: 150px;
  max-width: 1080px;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px; }
  @media (max-width: 767px) {
    .page {
      padding-top: 90px;
      padding-left: 10px;
      padding-right: 10px; } }
  .page.shrink {
    height: 0; }
  .page.login {
    max-width: 100vw; }

.icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block; }

.icon-spins {
  background-image: url(/static/media/i-spins.f1e0eb14.svg); }

.icon-angle {
  background-image: url(/static/media/i-angle.ed66675a.svg); }

.icon.eval {
  background-image: url(/static/media/i-eval.9eecaeef.svg); }

.icon.star {
  background-image: url(/static/media/star.8c638f80.svg); }

.icon.trophy {
  background-image: url(/static/media/trophy.3b0b55f4.svg); }

.icon.logout {
  background-image: url(/static/media/logout.41804760.svg);
  cursor: pointer;
  height: 20px;
  width: 20px; }

.icon.calendar {
  background-image: url(/static/media/i-calendar.33e7c3dd.svg);
  height: 25px;
  width: 25px; }

.icon.bell {
  background-image: url(/static/media/bell.780c98be.svg);
  width: 25px;
  height: 25px; }

.caret {
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #c8c8c8;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-left: -10px;
  margin-right: 10px;
  -webkit-transition: -webkit-transform .4s;
  transition: -webkit-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s; }
  .caret.down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  @media (max-width: 767px) {
    .caret {
      margin-left: 0px; } }

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  -webkit-transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px);
          transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px); }
  .loading .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .loading .circle.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s; }

.icon.listMenu {
  background-image: url(/static/media/listMenu.8a39a9b6.svg);
  height: 25px;
  width: 25px; }
  @media (max-width: 767px) {
    .icon.listMenu {
      height: 20px;
      width: 20px; } }

.icon.edit {
  background-image: url(/static/media/i-edit.b26e7a52.svg);
  height: 25px;
  width: 25px; }

.icon.remove {
  background-image: url(/static/media/i-delete.9df84da9.svg);
  height: 25px;
  width: 25px; }

.icon.note {
  background-image: url(/static/media/i-note.4215034a.svg); }

.icon.assessment {
  padding: 0;
  background-image: url(/static/media/i-assessment.04dedc0c.svg); }

.icon.questionnaire {
  margin: 0;
  background-image: url(/static/media/i-questionnaire.7d7ad6b5.svg); }

.icon.ride {
  background-image: url(/static/media/i-ride.26b548d2.svg); }

.icon.add {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/i-add.1e8f806f.svg); }

h2 {
  font-size: 24px;
  text-align: center;
  font-weight: normal;
  margin: 20px 0; }

h3 {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: -10px 0 0;
  font-size: 14px; }

.appBar {
  box-shadow: none !important;
  height: 100px; }

.flexRow {
  display: flex;
  justify-content: center; }
  .flexRow.column {
    flex-direction: column; }

.inputSearch {
  background-image: url(/static/media/search.847b363f.svg);
  background-size: 20px;
  background-position: center left 5px;
  background-repeat: no-repeat;
  padding: 0px 10px 0px 35px !important;
  line-height: 2;
  border-bottom: 1px solid #777; }
  .inputSearch input {
    padding: 5px;
    font-size: 24px; }

.direction {
  background-image: url(/static/media/rotation.26fa9d0f.svg);
  width: 35px;
  height: 35px;
  margin: 0 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  display: block;
  cursor: pointer; }
  .direction.white {
    background-image: url(/static/media/rotation-white.994d2a96.svg); }
  .direction.ccw {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

.button {
  border-radius: 50px !important;
  text-transform: none !important;
  width: 200px;
  box-shadow: none !important;
  font-weight: bold !important;
  line-height: 18px;
  padding: 2px;
  height: 50px;
  min-width: 40px !important; }
  .button:disabled {
    opacity: .5; }
  .button span {
    font-size: 18px; }
  @media (max-width: 767px) {
    .button {
      width: 150px;
      font-size: 18px !important; } }

.graphWrapper {
  border-radius: 15px;
  height: 200px;
  padding: 20px 5px 10px;
  margin: 20px auto;
  cursor: pointer; }
  @media (max-width: 767px) {
    .graphWrapper {
      height: 150px; } }
  .graphWrapper rect[height="180"] {
    width: 10px;
    -webkit-transform: translateX(15px);
            transform: translateX(15px); }
  .graphWrapper line {
    stroke: rgba(250, 250, 250, 0.1) !important; }
  .graphWrapper text {
    fill: #fff !important; }
  .graphWrapper table {
    margin: 0px -5px;
    width: 120px !important; }

.listExpandable {
  margin: 20px auto;
  padding-left: 0; }
  @media (max-width: 1024px) {
    .listExpandable {
      margin: 20px -20px; } }
  .listExpandable .evaluationConfig .angleSelectWrapper .angleSelect {
    width: 80px;
    margin-left: 45px; }
  .listExpandable .evaluationConfig .angleSelectWrapper .icon-angle {
    top: 2px;
    margin-right: 10px; }
  .listExpandable .evaluationConfig .durations {
    margin-top: -20px;
    padding: 0px 10px; }
    .listExpandable .evaluationConfig .durations .durationResult {
      display: flex;
      max-width: 225px;
      font-size: 24px;
      text-align: left;
      align-items: center; }
      .listExpandable .evaluationConfig .durations .durationResult .durationInput {
        position: relative;
        left: 13px;
        font-size: 24px;
        font-family: 'Cairo';
        max-width: 50px;
        text-align: center;
        padding: 0px 10px;
        margin: 5px 0;
        display: block;
        box-sizing: content-box;
        border-radius: 6px;
        border-style: none;
        border: 1px solid #c8c8c8; }
      .listExpandable .evaluationConfig .durations .durationResult .name {
        flex: 1 1;
        text-transform: capitalize;
        padding-left: 10px;
        font-size: 18px; }
      .listExpandable .evaluationConfig .durations .durationResult.right .icon {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .listExpandable .evaluationConfig .durations .durationResult.forward .icon {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      .listExpandable .evaluationConfig .durations .durationResult.left .icon {
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
      .listExpandable .evaluationConfig .durations .durationResult .icon {
        width: 15px;
        height: 15px; }
  .listExpandable .sessionTotals {
    margin-right: 25px;
    margin-left: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase; }
    @media (max-width: 805px) {
      .listExpandable .sessionTotals {
        margin-right: 50px; } }
    .listExpandable .sessionTotals.eval {
      width: 120px;
      display: none; }
      @media (min-width: 660px) {
        .listExpandable .sessionTotals.eval {
          display: block; } }
      @media (max-width: 805px) {
        .listExpandable .sessionTotals.eval {
          width: 100px; }
          .listExpandable .sessionTotals.eval span {
            margin-left: 0px; } }
    .listExpandable .sessionTotals.spins {
      width: 120px; }
      @media (max-width: 805px) {
        .listExpandable .sessionTotals.spins {
          width: 100px; } }
    .listExpandable .sessionTotals em {
      font-size: 12px;
      margin-left: 40px;
      font-weight: bold;
      font-style: normal; }
      @media (max-width: 1100px) {
        .listExpandable .sessionTotals em {
          display: none; } }
    .listExpandable .sessionTotals .icon {
      width: 15px;
      height: 15px; }
    .listExpandable .sessionTotals .icon-angle, .listExpandable .sessionTotals .icon-spins {
      display: none; }
    @media (max-width: 660px) {
      .listExpandable .sessionTotals.spins, .listExpandable .sessionTotals.angle {
        display: none; }
      .listExpandable .sessionTotals .total {
        margin-left: 5px !important;
        font-size: 14px !important; } }
    @media (max-width: 915px) {
      .listExpandable .sessionTotals.coreScore {
        margin-left: -15px; }
      .listExpandable .sessionTotals .icon {
        display: block; }
      .listExpandable .sessionTotals .label {
        display: none; } }
    .listExpandable .sessionTotals .total {
      margin-left: 10px;
      width: 20px;
      font-weight: bold;
      font-size: 24px; }

.expandableItem {
  list-style-type: none;
  border-top: 1px solid #c8c8c8;
  padding: 3px 0; }
  .expandableItem .locationSelectWrapper.session {
    pointer-events: none;
    position: relative;
    padding-left: 5px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 25px; }
    .expandableItem .locationSelectWrapper.session:before {
      margin-right: -15px;
      z-index: 5;
      position: relative;
      display: block;
      background-image: url(/static/media/location.5588985a.svg);
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      content: '';
      opacity: .2; }
    .expandableItem .locationSelectWrapper.session svg {
      opacity: 0; }
    .expandableItem .locationSelectWrapper.session.editing {
      pointer-events: all; }
      .expandableItem .locationSelectWrapper.session.editing svg {
        opacity: 1; }
  .expandableItem strong {
    font-weight: normal;
    position: relative;
    top: -1px; }
  .expandableItem .deleteModal {
    flex-direction: column;
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: -3px;
    background: rgba(250, 250, 250, 0.95);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center; }
    .expandableItem .deleteModal footer {
      margin-top: 15px; }
      .expandableItem .deleteModal footer button {
        margin: 0 20px; }
  .expandableItem .angle {
    text-align: right; }
    .expandableItem .angle .label {
      width: 100px; }
  .expandableItem p span {
    font-size: 12px;
    display: inline-block;
    margin-left: 20px; }
    @media (max-width: 767px) {
      .expandableItem p span {
        margin-left: 10px; } }
  .expandableItem footer {
    justify-content: flex-start; }
    .expandableItem footer button {
      margin: 20px 20px 20px 0; }
  .expandableItem.noteItem p {
    display: flex; }
  .expandableItem.noteItem strong {
    width: 180px; }
  .expandableItem.noteItem .noteInput {
    width: 100%;
    max-width: 100%;
    margin: -30px 0 0; }
  .expandableItem.noteItem .note.editing {
    padding-top: 25px; }
  .expandableItem.assessmentItem p {
    display: flex; }
  .expandableItem.assessmentItem strong {
    width: 180px; }
  .expandableItem.assessmentItem .donutChart:after {
    background-color: white; }
  .expandableItem.assessmentItem .assessmentResults {
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    position: relative;
    -webkit-transition: .25s;
    transition: .25s;
    top: 0;
    opacity: 1; }
    .expandableItem.assessmentItem .assessmentResults.expanded {
      opacity: 0;
      top: -30px; }
    @media (max-width: 705px) {
      .expandableItem.assessmentItem .assessmentResults {
        display: none; } }
  .expandableItem.assessmentItem .flexRow.assessment {
    justify-content: flex-start;
    max-width: 100%;
    margin-top: -20px;
    margin-left: -35px;
    opacity: .75;
    pointer-events: none; }
    @media (max-width: 900px) {
      .expandableItem.assessmentItem .flexRow.assessment {
        margin-left: 0px; } }
    .expandableItem.assessmentItem .flexRow.assessment.editing {
      opacity: 1;
      pointer-events: all; }
  .expandableItem.assessmentItem .result {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-transform: uppercase; }
    @media (max-width: 900px) {
      .expandableItem.assessmentItem .result {
        flex-direction: column; } }
    .expandableItem.assessmentItem .result .resultValue {
      font-weight: bold;
      margin-left: 10px;
      font-size: 24px;
      position: relative;
      top: -1px; }
      @media (max-width: 900px) {
        .expandableItem.assessmentItem .result .resultValue {
          margin-left: 0;
          font-size: 14px; } }
  .expandableItem.allcore360 .rideConfig {
    border-top: 0px;
    padding: 0;
    margin: -20px 0 0; }
    @media (max-width: 500px) {
      .expandableItem.allcore360 .rideConfig {
        margin: -20px 0 20px; } }
  .expandableItem.allcore360 strong {
    display: block;
    font-weight: normal;
    font-size: 18px;
    width: 175px; }
    @media (max-width: 500px) {
      .expandableItem.allcore360 strong {
        font-size: 16px;
        width: 155px; } }
  @media (max-width: 500px) {
    .expandableItem.allcore360 .caret {
      margin-left: 10px;
      margin-right: 5px; } }
  .expandableItem.allcore360 li {
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 20px;
    margin-bottom: 0px;
    pointer-events: none; }
    .expandableItem.allcore360 li .parameter {
      margin-right: 20px; }
    .expandableItem.allcore360 li .delete {
      margin-left: 20px; }
    .expandableItem.allcore360 li.editing {
      pointer-events: all; }
      .expandableItem.allcore360 li.editing input {
        border-radius: 6px;
        border: 1px solid #c8c8c8; }
    .expandableItem.allcore360 li .set {
      -webkit-animation: none;
              animation: none;
      padding: 20px 0px;
      justify-content: flex-start;
      border: 0px;
      display: flex; }
      @media (max-width: 660px) {
        .expandableItem.allcore360 li .set {
          padding: 0; } }
  .expandableItem .listMenu {
    cursor: pointer;
    z-index: 5;
    padding: 10px;
    background-size: auto;
    position: absolute;
    right: 5px;
    top: 15px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    margin-right: -10px; }
    @media (max-width: 767px) {
      .expandableItem .listMenu {
        right: 20px; } }
    .expandableItem .listMenu.show {
      opacity: 1;
      pointer-events: all; }
  .expandableItem [role="button"] {
    padding-left: 20px;
    background-color: white; }
  .expandableItem .listActions {
    padding-right: 0;
    position: absolute;
    right: -120px;
    opacity: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 300ms cubic-bezier(0.17, 0.89, 0.33, 1.15);
    transition: all 300ms cubic-bezier(0.17, 0.89, 0.33, 1.15);
    display: flex;
    align-items: center;
    height: 65px;
    pointer-events: none; }
    .expandableItem .listActions .icon {
      padding: 10px;
      background-size: 60%;
      background-color: white;
      -webkit-transition: background-color .25s;
      transition: background-color .25s;
      border-radius: 100%;
      cursor: pointer;
      background-color: white;
      -webkit-transition: .3s;
      transition: .3s; }
      .expandableItem .listActions .icon:hover {
        background-color: #eee; }
    .expandableItem .listActions.active {
      pointer-events: all;
      right: 30px;
      opacity: 1; }
      @media (max-width: 767px) {
        .expandableItem .listActions.active {
          right: 50px; } }
    .expandableItem .listActions .icon {
      margin-right: 30px; }
      @media (max-width: 767px) {
        .expandableItem .listActions .icon {
          margin-right: 0px; } }
  .expandableItem:last-child {
    border-bottom: 1px solid #c8c8c8; }

@media (max-width: 767px) {
  .tabBar > div {
    height: 65px; }
  .tabBar [role="tab"] {
    font-size: 14px;
    padding-top: 10px;
    max-width: 100px; } }

.questionnaireItem .empty {
  opacity: .35; }

.questionnaireItem strong {
  top: 2px; }

.questionnaireItem .summaryItem {
  font-size: 12px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-right: 20px; }
  @media (max-width: 660px) {
    .questionnaireItem .summaryItem {
      display: none; } }
  .questionnaireItem .summaryItem .label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal; }
  .questionnaireItem .summaryItem .percentage {
    display: block;
    position: relative;
    font-size: 14px;
    margin-left: 0;
    font-weight: normal;
    left: 1px; }
  .questionnaireItem .summaryItem span {
    font-size: 24px;
    font-weight: bold;
    position: relative;
    top: -1px;
    margin-left: 10px; }

.questionnaireItem .questionnaireContent {
  display: flex; }
  @media (max-width: 768px) {
    .questionnaireItem .questionnaireContent {
      flex-direction: column;
      margin-top: -25px; }
      .questionnaireItem .questionnaireContent .column {
        padding: 20px;
        margin-bottom: -20px; } }
  .questionnaireItem .questionnaireContent .column {
    flex: 1 1;
    margin-top: -35px;
    font-size: 14px; }
    .questionnaireItem .questionnaireContent .column:first-child {
      padding-right: 20px; }
    .questionnaireItem .questionnaireContent .column:last-child {
      padding-right: 20px; }
    .questionnaireItem .questionnaireContent .column .questionItem {
      margin: 15px 0; }
    .questionnaireItem .questionnaireContent .column header {
      font-weight: bold;
      display: flex;
      align-items: center; }
      .questionnaireItem .questionnaireContent .column header span {
        display: block;
        font-weight: bold;
        font-size: 24px;
        margin-right: 10px; }
    .questionnaireItem .questionnaireContent .column p {
      margin-top: -10px;
      line-height: 20px; }

.loadingText {
  text-transform: none;
  padding-top: 15px; }

.modal {
  -webkit-animation: fadeIn .3s;
          animation: fadeIn .3s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .modal .rideSummary {
    border-top: 0px; }
  .modal p {
    font-size: 18px;
    max-width: 450px;
    text-align: center;
    margin-bottom: 50px;
    line-height: 22px; }
    @media (max-width: 767px) {
      .modal p {
        padding: 0 25px; } }
  .modal footer button {
    margin: 0 25px; }
    @media (max-width: 767px) {
      .modal footer button {
        margin: 0 10px; } }
    .modal footer button.secondary {
      background-color: rgba(0, 0, 0, 0);
      color: white !important;
      border: 2px solid white !important; }

.form-group.disabled {
  pointer-events: none; }

footer.support {
  z-index: 10;
  position: fixed;
  text-align: center;
  font-size: 12px;
  color: #999;
  width: 100%;
  bottom: 8px;
  left: 0;
  right: 0; }
  @media (max-width: 767px) {
    footer.support {
      bottom: 3px;
      font-size: 10px; } }

.announcementsModal {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1200;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  flex-direction: column;
  opacity: 0;
  -webkit-transition: .4s;
  transition: .4s; }
  .announcementsModal .react-swipeable-view-container {
    max-width: 767px; }
  .announcementsModal .dismiss {
    background-color: rgba(250, 250, 250, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .announcementsModal.active {
    opacity: 1;
    pointer-events: all;
    background-color: rgba(250, 250, 250, 0.8); }
  .announcementsModal .announcement {
    -webkit-animation: bounceInUp .8s;
            animation: bounceInUp .8s;
    box-shadow: 2px 5px 20px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;
    margin: 20px;
    max-width: 767px;
    min-height: 80px;
    color: white;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    bottom: 0px;
    -webkit-transition: bottom .4s;
    transition: bottom .4s;
    flex-direction: column;
    position: relative; }
    .announcementsModal .announcement a {
      color: #77ba3e; }
    .announcementsModal .announcement header {
      font-size: 24px;
      margin-bottom: 10px; }
    .announcementsModal .announcement footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end; }
    .announcementsModal .announcement .description p {
      line-height: 22px;
      margin: 15px auto; }
  .announcementsModal .indicators {
    -webkit-animation: bounceInUp .8s;
            animation: bounceInUp .8s;
    display: flex;
    position: relative;
    z-index: 100; }
    .announcementsModal .indicators li {
      border: 1px solid rgba(0, 0, 0, 0.85);
      border-radius: 100%;
      width: 15px;
      height: 15px;
      margin: 5px;
      cursor: pointer; }
      .announcementsModal .indicators li.active {
        background-color: rgba(0, 0, 0, 0.85); }

.tooltip {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 14px; }
  .tooltip header {
    width: 100px;
    line-height: 22px;
    margin-right: 10px; }
  .tooltip:hover .tooltipText {
    bottom: 35px;
    pointer-events: all;
    opacity: 1; }
  .tooltip .icon {
    margin-right: 10px;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(/static/media/help.e5b4a815.svg);
    background-repeat: no-repeat;
    background-size: 100%; }
  .tooltip .tooltipText {
    background-color: white;
    border-radius: 6px;
    right: 0px;
    bottom: 15px;
    -webkit-transition: .3s;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    text-transform: none;
    text-transform: initial;
    z-index: 5;
    width: 350px;
    padding: 10px;
    box-shadow: 2px 5px 20px -10px rgba(0, 0, 0, 0.75); }
    @media (max-width: 768px) {
      .tooltip .tooltipText {
        right: auto;
        left: 0; } }

.waiverText {
  text-align: center;
  background-color: #e8e8e8;
  padding: 5px 10px 25px;
  margin-top: 20px;
  border-radius: 6px; }
  .waiverText p {
    margin: -10px 0 15px;
    line-height: 20px; }
  .waiverText a {
    text-decoration: none; }

#zohohc-asap-web-launcherbox {
  border-radius: 50px;
  width: 35px;
  height: 35px;
  right: 20px;
  bottom: 20px;
  z-index: 10; }
  @media (max-width: 767px) {
    #zohohc-asap-web-launcherbox {
      width: 20px;
      height: 20px;
      bottom: 10px;
      right: 10px; }
      #zohohc-asap-web-launcherbox svg {
        width: 16px;
        height: 16px;
        margin-top: 5px; } }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translateY(610px);
            transform: translateY(610px); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes slideUp {
  from {
    -webkit-transform: translateY(610px);
            transform: translateY(610px); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

.page.patientProfile {
  margin-top: 10px; }

.patientProfileForm {
  max-width: 600px;
  margin: 0 auto; }
  .patientProfileForm label {
    -webkit-transform: translate(0, 1.5px) scale(0.75);
            transform: translate(0, 1.5px) scale(0.75);
    -webkit-transform-origin: top left;
            transform-origin: top left; }
  @media (max-width: 500px) {
    .patientProfileForm footer.flexRow {
      flex-direction: row;
      margin-top: 15px; }
    .patientProfileForm .flexRow {
      flex-direction: column; }
      .patientProfileForm .flexRow .form-group {
        margin: 12px 20px;
        width: auto; } }
  .patientProfileForm .preferences {
    max-width: 590px;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    color: #777; }
  .patientProfileForm h2 {
    margin-bottom: 0; }
  .patientProfileForm .heightWeight .heightWrapper, .patientProfileForm .heightWeight .weightWrapper {
    display: flex;
    flex: 1 1; }
  .patientProfileForm .heightWeight .weightWrapper .form-group {
    width: 230px; }
  .patientProfileForm .heightWeight span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    left: -10px;
    bottom: -40px; }
  .patientProfileForm .form-group {
    margin: 20px;
    width: 260px; }
    .patientProfileForm .form-group.inputHeight {
      flex: 1 1;
      width: auto; }
      .patientProfileForm .form-group.inputHeight.inches label {
        opacity: 0; }
    .patientProfileForm .form-group input:disabled, .patientProfileForm .form-group select:disabled {
      color: black !important; }
    .patientProfileForm .form-group.disabled > div:before {
      border-bottom: 0px !important; }
    .patientProfileForm .form-group.disabled [role="button"] {
      color: #992525 !important; }
  .patientProfileForm .waiverStatus p {
    color: #777;
    display: flex;
    align-items: center;
    flex: 1 1; }
  .patientProfileForm .waiverStatus a {
    text-decoration: none;
    color: white;
    display: block;
    margin: 20px; }
  .patientProfileForm .waiverStatus .icon {
    background-image: url(/static/media/i-waiver.1fceece9.svg);
    width: 20px;
    height: 30px;
    margin-right: 5px;
    margin-left: 20px; }
  .patientProfileForm footer {
    margin-top: 50px;
    -webkit-animation: slideUp .4s;
            animation: slideUp .4s; }
    .patientProfileForm footer button {
      margin: 0px 25px; }

.userSearch {
  display: flex;
  padding: 10px;
  left: 0;
  background-color: white;
  right: 0;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  top: 100px;
  -webkit-transition: top 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: top 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); }
  .userSearch.slide {
    top: 250px; }
  .userSearch .addUser {
    cursor: pointer;
    opacity: .8;
    -webkit-transition: opacity .3s;
    transition: opacity .3s; }
    .userSearch .addUser span {
      height: 50px;
      display: flex;
      width: 50px;
      justify-content: center;
      align-items: center;
      font-size: 35px; }
    .userSearch .addUser:hover {
      opacity: 1; }
  @media (min-width: 1080px) {
    .userSearch {
      max-width: 1080px;
      margin: 0 auto; } }
  @media (max-width: 767px) {
    .userSearch {
      top: 90px;
      padding: 0; } }
  .userSearch .inputSearch {
    max-width: 1040px;
    margin: 0 auto;
    display: flex;
    justify-content: center; }
    @media (max-width: 767px) {
      .userSearch .inputSearch {
        background-position: center left 5px;
        padding: 0px 10px 0px 30px !important; }
        .userSearch .inputSearch input {
          font-size: 16px; } }
  .userSearch .search {
    margin: 0; }
  .userSearch fieldset {
    border: none; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: 10px; }
  to {
    opacity: 1;
    top: 0px; } }

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 10px; }
  to {
    opacity: 1;
    top: 0px; } }

.groupSelectWrapper {
  display: flex;
  justify-content: center;
  min-height: 48px; }

.groupSelect {
  position: relative; }
  .groupSelect [role="button"] {
    color: white;
    padding: 0 25px 0 0;
    font-size: 18px;
    overflow: visible; }
  .groupSelect svg {
    fill: white;
    top: -2px; }
  .groupSelect:after, .groupSelect:before {
    display: none; }

.addNewPatient {
  margin-top: -75px;
  position: relative;
  -webkit-animation: fadeIn .5s;
          animation: fadeIn .5s; }
  @media (max-width: 768px) {
    .addNewPatient {
      margin-top: -50px; } }

.page.users {
  padding-top: 175px; }
  @media (max-width: 1024px) {
    .page.users {
      padding-left: 0px;
      padding-right: 0px; } }
  @media (max-width: 767px) {
    .page.users {
      padding-top: 140px; } }
  .page.users.empty {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    padding-top: 200px; }

.patientTable {
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  font-family: 'Cairo', sans-serif; }
  @media (max-width: 1024px) {
    .patientTable {
      border-radius: 0px; } }
  @media (max-width: 767px) {
    .patientTable {
      display: block !important; }
      .patientTable thead, .patientTable tbody {
        display: block !important;
        max-width: 100%;
        font-size: 10px; }
        .patientTable thead tr, .patientTable tbody tr {
          height: 50px;
          display: flex !important;
          max-width: 100%; }
          .patientTable thead tr th, .patientTable thead tr td, .patientTable tbody tr th, .patientTable tbody tr td {
            display: flex !important;
            align-items: center; }
            .patientTable thead tr th a, .patientTable thead tr td a, .patientTable tbody tr th a, .patientTable tbody tr td a {
              width: 100%;
              padding: 25px 5px; }
            .patientTable thead tr th:first-child, .patientTable thead tr td:first-child, .patientTable tbody tr th:first-child, .patientTable tbody tr td:first-child {
              padding-right: 47px; }
            .patientTable thead tr th:last-child, .patientTable thead tr td:last-child, .patientTable tbody tr th:last-child, .patientTable tbody tr td:last-child {
              width: 100px;
              padding-right: 0px;
              justify-content: flex-start; }
            .patientTable thead tr th:nth-child(2), .patientTable thead tr td:nth-child(2), .patientTable tbody tr th:nth-child(2), .patientTable tbody tr td:nth-child(2) {
              flex: 1 1; }
      .patientTable td {
        font-size: 16px !important; } }
  .patientTable .columns {
    height: 50px;
    border: 1px solid blue; }
  .patientTable th {
    padding: 10px;
    font-size: 18px;
    text-align: left; }
    @media (max-width: 500px) {
      .patientTable th {
        font-size: 16px;
        padding: 5px; }
        .patientTable th:first-child {
          width: 60px; } }
  .patientTable tbody td {
    cursor: pointer;
    padding: 0 !important; }
    @media (max-width: 500px) {
      .patientTable tbody td:first-child {
        width: 113px; } }
    .patientTable tbody td a {
      text-decoration: none;
      color: inherit;
      display: block;
      padding: 25px 10px; }
      @media (max-width: 500px) {
        .patientTable tbody td a {
          display: flex;
          font-size: 12px;
          align-content: center;
          padding: 5px;
          line-height: 12px; } }

.rideTotals {
  margin-top: -25px;
  padding: 0;
  height: 83px;
  align-items: flex-end; }
  @media (max-width: 767px) {
    .rideTotals {
      margin-top: -50px; } }
  .rideTotals .loading {
    -webkit-transform: scale(0.15);
            transform: scale(0.15);
    position: relative;
    left: -10px;
    top: 5px; }
  .rideTotals li {
    list-style-type: none;
    text-align: center;
    text-transform: uppercase;
    width: 125px; }
    .rideTotals li span {
      display: block;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: -15px; }
    @media (max-width: 767px) {
      .rideTotals li {
        font-size: 10px; }
        .rideTotals li span {
          margin-bottom: -10px;
          font-size: 24px; } }

.userNewForm {
  max-width: 550px;
  margin: 0 auto; }
  .userNewForm .form-group {
    margin-bottom: 50px; }
  @media (max-width: 767px) {
    .userNewForm {
      flex-direction: column; }
      .userNewForm .form-group {
        margin-bottom: 30px; } }

.dateRange {
  align-items: center;
  margin-top: 20px; }
  .dateRange .icon {
    margin-bottom: -5px; }
  .dateRange .form-group {
    margin: 0 20px; }
    @media (max-width: 500px) {
      .dateRange .form-group input {
        font-size: 14px; } }

.tooltip header, .legend header {
  font-size: 18px; }

.tooltip ul, .legend ul {
  margin: 0;
  padding: 0; }

.tooltip li, .legend li {
  display: flex;
  align-items: center;
  list-style-type: none; }

.tooltip .value, .legend .value {
  font-weight: bold;
  font-size: 24px;
  flex: 1 1;
  text-align: right; }

.tooltip i, .legend i {
  font-style: normal;
  position: absolute;
  display: block;
  right: -4px;
  font-size: 18px;
  margin-top: -4px;
  font-weight: bold; }

.tooltip span, .legend span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  margin-right: 5px; }

.switchWrapper {
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px; }
  .switchWrapper .active {
    font-weight: bold; }
  .switchWrapper .switch > span:first-child span {
    color: white; }
  .switchWrapper .switch > span:nth-child(2) {
    background-color: #000 !important;
    opacity: .38 !important; }
  .switchWrapper span {
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase; }

.legend span {
  margin-right: 20px; }

.legendKey {
  margin-right: 5px !important;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%; }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rideDetails {
  align-items: baseline;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 -20px;
  padding-bottom: 30px; }
  @media (max-width: 500px) {
    .rideDetails {
      margin: 0 auto;
      padding-bottom: 0px; }
      .rideDetails > .flexRow {
        flex-direction: column;
        align-items: center; }
      .rideDetails .rideDetailSelectWrapper {
        margin-bottom: 15px;
        margin-right: 25px;
        width: 100%; }
        .rideDetails .rideDetailSelectWrapper .rideDetailSelect {
          width: 100%; }
        .rideDetails .rideDetailSelectWrapper > div {
          width: 100%; } }
  .rideDetails .rideDetailSelectWrapper {
    margin: 0 20px; }
    .rideDetails .rideDetailSelectWrapper .rideDetailSelect {
      width: 175px; }
      @media (max-width: 500px) {
        .rideDetails .rideDetailSelectWrapper .rideDetailSelect {
          width: 100%;
          margin: 20px 0; } }
      .rideDetails .rideDetailSelectWrapper .rideDetailSelect:before {
        display: none; }
  .rideDetails .rideMode .switchWrapper {
    margin-top: 0; }

.angleSelectWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 768px) {
    .angleSelectWrapper {
      margin-bottom: -15px; } }
  .angleSelectWrapper h2 {
    margin-bottom: 5px; }
  .angleSelectWrapper .icon {
    position: relative;
    top: 8px;
    margin-right: 15px;
    width: 15px;
    height: 15px; }
  .angleSelectWrapper .angleSelect {
    width: 115px; }

.coreScoreWrapper {
  -webkit-animation: pulse 1s;
          animation: pulse 1s;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 10px; }
  .coreScoreWrapper .scoreItem {
    margin: 5px 40px; }
  .coreScoreWrapper .icon {
    width: 25px;
    height: 25px;
    margin-right: 5px; }
  .coreScoreWrapper .score {
    margin-top: -15px;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center; }
  .coreScoreWrapper footer {
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;
    font-weight: bold; }

.dateEditWrapper {
  justify-content: flex-start;
  position: absolute;
  background-color: white;
  top: 15px;
  left: 30px;
  padding: 5px; }

.parameter-input {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative; }
  .parameter-input label {
    margin-left: 5px; }
  .parameter-input input[type='number'] {
    border: 0;
    border-radius: 6px;
    padding: 5px !important;
    -moz-appearance: textfield;
    display: inline-block;
    font-family: 'Cairo', sans-serif;
    font-size: 36px;
    height: 52px;
    line-height: 36px;
    margin: 0;
    text-align: center;
    width: 65px; }
    @media (max-width: 450px) {
      .parameter-input input[type='number'] {
        width: 35px; } }
    @media (max-width: 1024px) {
      .parameter-input input[type='number'] {
        font-size: 24px;
        height: 50px; } }
    .parameter-input input[type='number']::-webkit-outer-spin-button, .parameter-input input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .parameter-input .stepper {
    -webkit-user-select: none;
    background-image: url(/static/media/caret.5814c9e9.svg);
    background-position: left 50% center;
    background-repeat: no-repeat;
    background-size: 10px;
    border: 2px solid #c8c8c8;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    height: 35px;
    margin: 0 5px;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    width: 35px; }
    .parameter-input .stepper.less {
      transform: rotate(180deg); }
  .parameter-input.disabled .stepper {
    opacity: .2;
    pointer-events: none; }
  .parameter-input.white .stepper {
    background-image: url(/static/media/caret-white.5548d856.svg); }
  @media (max-width: 767px) {
    .parameter-input .stepper {
      display: none; } }

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.startRideAction {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  bottom: 80px; }
  .startRideAction .column .button {
    width: 200px; }
  .startRideAction .coreScore {
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: -10px; }
    .startRideAction .coreScore .star {
      width: 15px;
      height: 15px;
      margin-left: 10px; }
    .startRideAction .coreScore .score {
      font-size: 24px;
      margin-left: 5px;
      font-weight: bold; }
  .startRideAction .button {
    margin: 15px; }
  @media (min-width: 768px) {
    .startRideAction {
      bottom: 101px; }
      .startRideAction .button {
        margin: 25px; } }

.addSet {
  margin-top: 25px; }

.rideSummary {
  border-top: 1px solid #e8e8e8; }

.rideConfig, .rideSummary {
  padding-bottom: 90px;
  margin: 0 -20px; }
  .rideConfig ol, .rideSummary ol {
    margin: 0px;
    padding: 0; }
    .rideConfig ol li, .rideSummary ol li {
      overflow: hidden;
      list-style-type: none; }
      .rideConfig ol li .set, .rideSummary ol li .set {
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        padding: 30px 15px;
        line-height: 40px;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
        text-transform: uppercase;
        -webkit-animation: slideIn .5s;
                animation: slideIn .5s;
        align-items: center; }
        @media (max-width: 767px) {
          .rideConfig ol li .set, .rideSummary ol li .set {
            font-size: 14px;
            padding: 10px; } }
      .rideConfig ol li .parameter, .rideSummary ol li .parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        line-height: 20px; }
        @media (max-width: 767px) {
          .rideConfig ol li .parameter, .rideSummary ol li .parameter {
            height: 70px; } }
        @media (max-width: 590px) {
          .rideConfig ol li .parameter, .rideSummary ol li .parameter {
            flex-direction: column; } }
        .rideConfig ol li .parameter input[type=checkbox], .rideSummary ol li .parameter input[type=checkbox] {
          display: none; }
        .rideConfig ol li .parameter label, .rideSummary ol li .parameter label {
          margin: 0 10px; }
        .rideConfig ol li .parameter.parameter-direction, .rideSummary ol li .parameter.parameter-direction {
          cursor: pointer;
          flex-direction: row; }
          .rideConfig ol li .parameter.parameter-direction .direction-label, .rideSummary ol li .parameter.parameter-direction .direction-label {
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            line-height: 0;
            -webkit-transition: line-height .3s;
            transition: line-height .3s; }
            .rideConfig ol li .parameter.parameter-direction .direction-label :first-child, .rideSummary ol li .parameter.parameter-direction .direction-label :first-child {
              opacity: 0;
              -webkit-transition: opacity .3s;
              transition: opacity .3s; }
            .rideConfig ol li .parameter.parameter-direction .direction-label.direction-ccw-label, .rideSummary ol li .parameter.parameter-direction .direction-label.direction-ccw-label {
              line-height: 1; }
              .rideConfig ol li .parameter.parameter-direction .direction-label.direction-ccw-label :first-child, .rideSummary ol li .parameter.parameter-direction .direction-label.direction-ccw-label :first-child {
                opacity: 1; }

.delete {
  background-image: url(/static/media/x.e4becc7a.svg);
  background-repeat: no-repeat;
  background-position: center center;
  height: 35px;
  width: 35px;
  border: 2px solid #c8c8c8;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  margin-left: 10px;
  flex-shrink: 0; }
  @media (max-width: 767px) {
    .delete {
      width: 30px;
      height: 30px; } }
  .delete.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer; }

.modal.confirm {
  padding-bottom: 75px; }
  .modal.confirm .evaluationComplete ol li {
    align-items: center;
    font-size: 22px;
    height: 36px; }
  .modal.confirm .coreScore {
    margin-bottom: 20px;
    margin-top: -50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    justify-content: center; }
    .modal.confirm .coreScore .icon {
      margin: 0 5px 0 10px;
      width: 20px;
      height: 20px; }
    .modal.confirm .coreScore .score {
      font-size: 24px;
      font-weight: bold; }
  .modal.confirm .rideSummary {
    flex: 0 1;
    margin-bottom: 50px; }
    .modal.confirm .rideSummary li {
      padding: 10px 0px; }
  .modal.confirm footer.flexRow {
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0; }
  .modal.confirm .newNote {
    width: 600px;
    background-color: rgba(0, 0, 0, 0);
    margin: 25px auto; }
    @media (max-width: 650px) {
      .modal.confirm .newNote {
        width: 90vw;
        margin: 0; }
        .modal.confirm .newNote textarea {
          font-size: 12px; } }
    .modal.confirm .newNote fieldset {
      border: 0px; }
    .modal.confirm .newNote textarea {
      color: white; }
  .modal.confirm .noteConfirm {
    pointer-events: none; }
  .modal.confirm .questionnaireConfirm .empty {
    opacity: .35; }
  .modal.confirm .assessment {
    margin-bottom: 50px;
    pointer-events: none; }
  .modal.confirm .assessmentParameter header {
    color: white; }
  .modal.confirm .assessmentParameter input {
    color: white !important; }
  .modal.confirm .assessmentParameter .donutChart {
    background-color: rgba(0, 0, 0, 0) !important; }
    .modal.confirm .assessmentParameter .donutChart svg path {
      stroke-width: 3px; }
    .modal.confirm .assessmentParameter .donutChart:after {
      background-color: rgba(0, 0, 0, 0); }
  .modal.confirm ol {
    flex: 1 1;
    margin-top: 30px; }
    .modal.confirm ol li {
      font-size: 14px; }
      .modal.confirm ol li:first-child strong {
        margin-top: 0px; }
      .modal.confirm ol li strong {
        display: block;
        line-height: 16px;
        margin-top: 15px;
        margin-bottom: 5px; }
      .modal.confirm ol li span {
        display: block;
        line-height: 14px; }

.evaluationOverview {
  max-width: 500px;
  margin: 0px auto;
  line-height: 22px;
  padding: 0 5px 55px 5px; }
  .evaluationOverview p {
    margin: 15px 0; }
  .evaluationOverview ol {
    padding-bottom: 50px; }
    @media (max-width: 500px) {
      .evaluationOverview ol {
        margin-right: 20px; } }
    .evaluationOverview ol li {
      border-radius: 6px;
      background-color: #ebebeb;
      margin: 20px 10px;
      padding: 10px;
      position: relative;
      top: 0;
      -webkit-transition: .2s;
      transition: .2s; }
      .evaluationOverview ol li.slideUp {
        top: -31px; }
      .evaluationOverview ol li.slideDown {
        top: 31px; }
  .evaluationOverview .arrowUp, .evaluationOverview .arrowDown {
    cursor: pointer;
    float: right;
    margin-right: -35px;
    width: 15px;
    height: 15px;
    display: none;
    background-image: url(/static/media/caret-green.5a027f3a.svg);
    background-size: contain;
    background-repeat: no-repeat; }
    .evaluationOverview .arrowUp.active, .evaluationOverview .arrowDown.active {
      display: block; }
  .evaluationOverview .arrowUp {
    margin-top: -5px; }
  .evaluationOverview .arrowDown {
    margin-top: -10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .evaluationOverview ul li {
    margin: 25px 0; }
    .evaluationOverview ul li strong {
      display: block;
      text-transform: uppercase; }

.averageAngle {
  display: flex;
  flex-direction: column;
  margin: -4px 20px 0;
  -webkit-animation: fadeInLeft 1s;
          animation: fadeInLeft 1s; }
  @media (max-width: 767px) {
    .averageAngle {
      padding-bottom: 30px;
      width: 100%; }
      .averageAngle .parameter-input {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .averageAngle .parameter-input .stepper {
          display: block;
          width: 30px;
          height: 30px; } }
  .averageAngle label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px; }
  .averageAngle input[type="number"]::-webkit-outer-spin-button,
  .averageAngle input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .averageAngle input[type="number"] {
    font-size: 28px;
    text-align: center;
    display: inline-block;
    height: 55px;
    border: 0;
    width: 55px;
    -moz-appearance: textfield; }
  .averageAngle .stepper {
    width: 20px;
    height: 20px;
    margin: 0; }
  .averageAngle input {
    font-size: 36px;
    line-height: 36px;
    width: auto;
    font-family: 'Cairo', sans-serif;
    text-align: center;
    display: inline-block;
    height: 52px;
    margin: 0;
    border: 0;
    width: 65px; }

.rideTemplateSelect {
  max-width: 1080px;
  margin: 0 auto; }
  @media (max-width: 500px) {
    .rideTemplateSelect {
      margin: 0 20px; } }
  .rideTemplateSelect .rideTemplate {
    cursor: pointer;
    padding: 0 20px; }
    @media (max-width: 500px) {
      .rideTemplateSelect .rideTemplate {
        padding: 0; } }
  .rideTemplateSelect .rideContent {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px; }
    .rideTemplateSelect .rideContent .flexRow {
      justify-content: center;
      align-items: center; }
      .rideTemplateSelect .rideContent .flexRow .diagram {
        margin: 0 20px;
        border-radius: 6px;
        width: 200px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: 200px; }
        .rideTemplateSelect .rideContent .flexRow .diagram[data-template="steadyBurn"] {
          background-image: url(/static/media/template-5x5.dac91cf7.svg); }
        .rideTemplateSelect .rideContent .flexRow .diagram[data-template="middlePassage"] {
          background-image: url(/static/media/template-cool-down.513d69ee.svg); }
        .rideTemplateSelect .rideContent .flexRow .diagram[data-template="theGrind"] {
          background-image: url(/static/media/template-deep-dive.7c41bb30.svg); }
      .rideTemplateSelect .rideContent .flexRow .rideSummary {
        padding: 10px 0;
        margin-bottom: 15px;
        border-top: 0px; }
        .rideTemplateSelect .rideContent .flexRow .rideSummary .parameter:first-child {
          width: 125px; }
        @media (max-width: 768px) {
          .rideTemplateSelect .rideContent .flexRow .rideSummary li, .rideTemplateSelect .rideContent .flexRow .rideSummary .parameter {
            padding: 5px 0; } }
    .rideTemplateSelect .rideContent header {
      font-size: 20px;
      flex: 1 1; }
      .rideTemplateSelect .rideContent header span {
        font-size: 14px;
        color: #777;
        display: block;
        margin-top: -5px; }
    @media (max-width: 500px) {
      .rideTemplateSelect .rideContent {
        padding: 10px; }
        .rideTemplateSelect .rideContent .flexRow .diagram {
          width: 80px;
          height: 25px;
          background-size: 80px;
          margin: 0 10px 0 -10px; }
        .rideTemplateSelect .rideContent .coreScoreWrapper {
          padding-top: 0px;
          font-size: 14px; }
          .rideTemplateSelect .rideContent .coreScoreWrapper .score {
            margin-top: 0; }
          .rideTemplateSelect .rideContent .coreScoreWrapper .icon {
            width: 15px;
            height: 15px;
            margin-right: 3px;
            margin-bottom: -1px; }
          .rideTemplateSelect .rideContent .coreScoreWrapper h3 {
            font-size: 12px; }
        .rideTemplateSelect .rideContent header {
          font-size: 16px; }
          .rideTemplateSelect .rideContent header span {
            font-size: 12px; } }
    .rideTemplateSelect .rideContent .coreScoreWrapper {
      -webkit-animation: none;
              animation: none; }

@charset "UTF-8";
.evaluationComplete ol {
  list-style-type: none;
  text-transform: capitalize;
  font-size: 18px; }
  .evaluationComplete ol li {
    justify-content: space-between;
    width: 300px; }
    .evaluationComplete ol li:last-child {
      font-size: 24px;
      margin-top: 25px;
      margin-bottom: 50px; }

.message {
  height: 50px;
  padding-bottom: 20px; }
  .message p {
    margin: 0 auto; }
    @media (max-width: 500px) {
      .message p {
        max-width: 90vw; } }

.coreEvaluation h2 {
  margin-bottom: 30px; }

.coreEvaluation .currentAngle {
  font-size: 18px;
  text-align: center;
  margin-top: -35px;
  margin-bottom: 20px; }
  .coreEvaluation .currentAngle .icon.angle.white {
    margin: 0px 5px 0px 10px;
    background-image: url(/static/media/i-angle-white.9136b827.svg);
    width: 15px;
    height: 15px;
    position: relative;
    top: 1px; }

@media (max-width: 767px) {
  .coreEvaluation button {
    width: 200px;
    padding: 0px; } }

@media (max-width: 480px) {
  .coreEvaluation button {
    width: 160px; }
    .coreEvaluation button span {
      font-size: 14px; } }

.coreEvaluation .pauseText {
  margin: 0px auto;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin-top: -32px;
  font-size: 14px;
  text-transform: uppercase; }

.evaluationWrapper {
  margin-bottom: 40px; }
  @media (max-width: 560px) {
    .evaluationWrapper {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      margin-bottom: -30px;
      margin-top: -60px; } }
  @media (max-width: 430px) {
    .evaluationWrapper {
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
      margin-bottom: -90px;
      margin-top: -100px; } }
  .evaluationWrapper .angleWrapper {
    position: absolute;
    z-index: 15;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .evaluationWrapper .angle {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;
    font-size: 36px; }
    .evaluationWrapper .angle:after {
      content: '°';
      display: block;
      font-size: 18px;
      position: absolute;
      right: 8px;
      top: 15px; }
  .evaluationWrapper .circle {
    border-radius: 100%;
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: relative; }
    .evaluationWrapper .circle .row {
      width: 100%;
      flex: 1 1; }
    .evaluationWrapper .circle .flexRow.topRight .quadrant:first-child .inner {
      margin-right: -40px; }
      .evaluationWrapper .circle .flexRow.topRight .quadrant:first-child .inner header {
        margin-top: 20px; }
    .evaluationWrapper .circle .flexRow.topRight .quadrant:last-child .inner {
      margin-left: -40px; }
      .evaluationWrapper .circle .flexRow.topRight .quadrant:last-child .inner header {
        width: 125px;
        position: absolute;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        right: -105px;
        top: 32px; }
      .evaluationWrapper .circle .flexRow.topRight .quadrant:last-child .inner span {
        margin-top: 0;
        margin-left: -80px; }
    .evaluationWrapper .circle .flexRow.bottomLeft .quadrant:last-child .inner {
      margin-left: -35px;
      -webkit-transform: rotate(-45deg) translateY(20px);
              transform: rotate(-45deg) translateY(20px); }
      .evaluationWrapper .circle .flexRow.bottomLeft .quadrant:last-child .inner header {
        margin-bottom: -120px; }
    .evaluationWrapper .circle .flexRow.bottomLeft .quadrant:first-child .inner {
      margin-right: -35px; }
      .evaluationWrapper .circle .flexRow.bottomLeft .quadrant:first-child .inner header {
        position: absolute;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        left: -100px;
        top: 32px;
        width: 125px; }
      .evaluationWrapper .circle .flexRow.bottomLeft .quadrant:first-child .inner span {
        margin-top: 0;
        margin-right: -80px; }
    .evaluationWrapper .circle .quadrant {
      z-index: -1;
      position: relative;
      height: 250px;
      width: 290px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .evaluationWrapper .circle .quadrant:first-child {
        border-left: none; }
      .evaluationWrapper .circle .quadrant:last-child {
        border-right: none; }
      .evaluationWrapper .circle .quadrant .inner {
        text-align: center;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        text-transform: uppercase; }
        .evaluationWrapper .circle .quadrant .inner header {
          font-weight: bold; }
        .evaluationWrapper .circle .quadrant .inner span {
          font-weight: 100;
          font-size: 48px;
          display: block; }

.page.patientNotes {
  padding-top: 115px;
  padding-left: 0;
  padding-right: 0; }
  @media (max-width: 475px) {
    .page.patientNotes {
      padding-top: 100px; } }
  .page.patientNotes .tabsSecondary {
    max-width: 767px;
    margin: 10px auto 25px auto; }
  .page.patientNotes .subPage {
    margin-top: -100px;
    padding-top: 80px; }
  .page.patientNotes .inset button {
    margin: 25px; }
    @media (max-width: 600px) {
      .page.patientNotes .inset button {
        margin: 0 15px; } }
  .page.patientNotes .newNote {
    max-width: 600px;
    background-color: white;
    margin: 25px auto;
    display: flex; }

@media (max-width: 600px) {
  .patientQuestionnaire .inset button {
    margin: 25px !important; } }

.questionnaire {
  max-width: 550px;
  margin: 20px auto; }
  .questionnaire p {
    margin: 20px auto;
    line-height: 24px; }
  .questionnaire ol {
    margin: 10px auto; }
    .questionnaire ol li {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px; }
      .questionnaire ol li strong {
        line-height: 20px; }
      .questionnaire ol li span {
        line-height: 16px; }
      .questionnaire ol li label {
        margin-bottom: -8px;
        display: flex; }
        @media (max-width: 500px) {
          .questionnaire ol li label {
            margin-bottom: 5px; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.invalidAssessment {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  -webkit-animation: fadeIn .5s;
          animation: fadeIn .5s; }

.assessment {
  padding: 20px 0;
  max-width: 620px;
  margin: 0 auto;
  justify-content: space-between; }
  @media (max-width: 600px) {
    .assessment {
      padding: 10px 0;
      justify-content: center; } }

.assessmentParameter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  flex-direction: column; }
  @media (max-width: 600px) {
    .assessmentParameter {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
      width: 70px;
      flex: 1 1; } }
  .assessmentParameter header {
    text-align: center;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
    font-weight: bold;
    font-size: 14px; }
    @media (max-width: 600px) {
      .assessmentParameter header {
        font-size: 12px;
        line-height: 12px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center; } }
  .assessmentParameter input {
    background: none;
    border: 0px;
    text-align: center;
    font-size: 28px;
    font-family: 'Cairo';
    width: 65px; }
    @media (max-width: 1024px) {
      .assessmentParameter input {
        height: 50px;
        font-size: 24px; } }
  .assessmentParameter input[type="number"]::-webkit-outer-spin-button,
  .assessmentParameter input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .assessmentParameter input[type="number"] {
    -moz-appearance: textfield; }
  .assessmentParameter .inputWrapper {
    display: flex;
    justify-content: center;
    right: 0;
    margin-top: -70px;
    z-index: 1;
    align-items: center;
    width: 70px;
    height: 60px; }
  .assessmentParameter .donutChart {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    position: relative;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center; }
    .assessmentParameter .donutChart svg {
      position: absolute; }
      .assessmentParameter .donutChart svg path {
        -webkit-transition: .4s;
        transition: .4s; }
    .assessmentParameter .donutChart:after {
      background-color: #ebebeb;
      display: block;
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      top: 5px;
      border-radius: 100%;
      left: 5px; }

.patientOverview {
  max-width: 767px;
  margin: 0 auto; }
  .patientOverview .assessmentParameter .donutChart:after {
    background-color: white; }
  .patientOverview section {
    margin: 0px auto 50px; }
    .patientOverview section em {
      line-height: 18px;
      display: block;
      margin-top: 10px; }
      @media (max-width: 550px) {
        .patientOverview section em {
          font-size: 14px; } }
    .patientOverview section .sectionHeader {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      padding: 10px 10px 10px 15px; }
      .patientOverview section .sectionHeader h2 {
        text-align: left;
        flex: 1 1; }
        .patientOverview section .sectionHeader h2 div {
          display: inline-block; }
        .patientOverview section .sectionHeader h2 span {
          min-width: 130px;
          font-size: 12px;
          color: #888;
          display: block;
          margin-left: 20px;
          margin-top: -8px; }
      .patientOverview section .sectionHeader .icon {
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;
        -webkit-transition: .15s;
        transition: .15s;
        cursor: pointer;
        left: -10px;
        -webkit-transform: scale(1);
                transform: scale(1); }
        .patientOverview section .sectionHeader .icon:hover {
          -webkit-transform: scale(1.25);
                  transform: scale(1.25); }
    .patientOverview section h3 {
      margin: 0; }
    .patientOverview section h2 {
      font-size: 18px;
      margin: 0; }
  .patientOverview .rideSummary {
    border-top: 0px;
    margin-left: -40px; }
  .patientOverview .rideContent {
    display: flex;
    padding-top: 5px; }
    @media (max-width: 590px) {
      .patientOverview .rideContent {
        flex-direction: column; } }
    .patientOverview .rideContent .scoreItem {
      margin: 5px 0; }
      .patientOverview .rideContent .scoreItem h3 {
        text-align: right; }
  .patientOverview .lastAssessment .assessment {
    max-width: 100%;
    margin: 0; }
  .patientOverview .coreScoreWrapper {
    flex: 1 1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start; }
    .patientOverview .coreScoreWrapper .score {
      justify-content: flex-end;
      margin-bottom: -20px; }
    @media (max-width: 768px) {
      .patientOverview .coreScoreWrapper {
        padding-right: 20px; } }
    @media (max-width: 590px) {
      .patientOverview .coreScoreWrapper {
        flex-direction: row;
        justify-content: space-around;
        padding-right: 0; }
        .patientOverview .coreScoreWrapper .score {
          justify-content: center; }
        .patientOverview .coreScoreWrapper .scoreItem {
          margin: 0px 20px; }
          .patientOverview .coreScoreWrapper .scoreItem h3 {
            text-align: center; } }
    @media (max-width: 400px) {
      .patientOverview .coreScoreWrapper h3 {
        font-size: 12px; } }
  .patientOverview .lastNoteValue {
    margin: 20px auto 50px;
    background-color: white;
    padding: 20px;
    box-shadow: 3px 6px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px; }
  .patientOverview .questionnaireItem {
    max-width: 900px; }
    .patientOverview .questionnaireItem .summary {
      text-align: center;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .patientOverview .questionnaireItem .summaryItem {
      display: flex !important; }
    .patientOverview .questionnaireItem .questionnaireContent {
      padding: 10px;
      margin-top: 20px; }
  .patientOverview .evaluationWrapper {
    -webkit-transform: none !important;
            transform: none !important; }
    .patientOverview .evaluationWrapper ol {
      flex: 1 1;
      margin: 10px 20px 10px 0px; }
    .patientOverview .evaluationWrapper .summary {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 150px; }
      @media (max-width: 768px) {
        .patientOverview .evaluationWrapper .summary {
          padding-right: 20px; } }
      .patientOverview .evaluationWrapper .summary .angle {
        margin-bottom: 20px; }
        .patientOverview .evaluationWrapper .summary .angle:after {
          right: -9px;
          top: -2px; }
      .patientOverview .evaluationWrapper .summary span {
        width: auto;
        height: 40px;
        line-height: 36px;
        text-align: right;
        font-weight: bold;
        font-size: 36px; }
    .patientOverview .evaluationWrapper li {
      position: relative;
      border-radius: 6px;
      padding: 0px 15px;
      margin: 10px;
      color: rgba(0, 0, 0, 0.8); }
      .patientOverview .evaluationWrapper li .background {
        border-radius: 0px 6px 6px 6px;
        bottom: 0;
        left: 0;
        position: absolute;
        height: 5px;
        z-index: 1; }
      .patientOverview .evaluationWrapper li header {
        position: relative;
        z-index: 5;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
        display: flex; }
      .patientOverview .evaluationWrapper li span {
        flex: 1 1;
        text-align: right; }
    @media (max-width: 590px) {
      .patientOverview .evaluationWrapper .flexRow {
        flex-direction: column;
        justify-content: center; }
      .patientOverview .evaluationWrapper .summary {
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-right: 0; }
        .patientOverview .evaluationWrapper .summary .angle {
          margin-bottom: 0;
          margin-right: 40px; }
        .patientOverview .evaluationWrapper .summary h3 {
          margin: 0 10px; } }

.rideSummary {
  padding: 0;
  margin: 0;
  flex: 1 1; }
  .rideSummary li {
    position: relative;
    display: flex;
    padding: 8px;
    line-height: 40px;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500; }
    @media (max-width: 767px) {
      .rideSummary li {
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
        margin-bottom: -20px; }
        .rideSummary li .parameter {
          width: 140px;
          margin: 0 !important; } }
    .rideSummary li .setDirection {
      width: 100px;
      line-height: 14px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      height: 35px;
      justify-content: center; }
    .rideSummary li .parameter {
      display: flex;
      padding-left: 10px;
      margin: 0px 10px;
      align-items: center; }
      .rideSummary li .parameter .number {
        width: 40px;
        text-align: center;
        font-size: 36px;
        opacity: 1;
        position: relative;
        font-weight: 500;
        line-height: 36px;
        margin: 0px 10px 0px 15px; }
      .rideSummary li .parameter .direction {
        margin: 0px 10px 0px 15px; }
      .rideSummary li .parameter span {
        font-weight: 500;
        margin-right: 10px;
        display: block;
        opacity: 0;
        top: 0;
        position: absolute;
        -webkit-transition: .3s;
        transition: .3s; }
      .rideSummary li .parameter label {
        padding-top: 0;
        margin-bottom: 0;
        position: relative; }
      .rideSummary li .parameter.ccw {
        line-height: 40px; }
        .rideSummary li .parameter.ccw label {
          padding-top: 10px;
          margin-bottom: -10px; }
        .rideSummary li .parameter.ccw span {
          top: -10px;
          opacity: 1 !important; }
  .rideSummary li {
    display: flex; }

.locationsList, .groupsList {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.page.settingsLocations {
  max-width: 1024px; }
  @media (max-width: 1024px) {
    .page.settingsLocations {
      padding-right: 0;
      padding-left: 0; } }

.page.settingsWaivers {
  max-width: 600px; }
  .page.settingsWaivers .flexRow {
    padding: 0 20px;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center; }
  .page.settingsWaivers footer.flexRow {
    justify-content: center; }
    .page.settingsWaivers footer.flexRow button {
      margin: 20px; }

.expandableItem.location {
  border-top: 1px solid #c8c8c8; }
  @media (max-width: 767px) {
    .expandableItem.location .flexRow {
      flex-direction: column; }
    .expandableItem.location .tooltip {
      margin-bottom: 15px; }
    .expandableItem.location footer.flexRow {
      flex-direction: row; } }
  .expandableItem.location .material-icons {
    font-size: 24px;
    margin-right: 5px;
    margin-left: 5px; }
  .expandableItem.location .caret {
    margin-left: 10px; }
  .expandableItem.location p {
    display: flex; }
  @media (max-width: 1024px) {
    .expandableItem.location {
      padding: 5px 20px; } }
  .expandableItem.location [role="button"] {
    padding-left: 0px; }
  .expandableItem.location:last-child {
    border-bottom: 1px solid #c8c8c8; }
  .expandableItem.location .flexRow {
    justify-content: flex-start; }
  .expandableItem.location .form-group {
    margin-bottom: 20px;
    margin-right: 40px; }
    .expandableItem.location .form-group.half-width {
      width: 50%; }
    .expandableItem.location .form-group input:disabled {
      color: black; }
    .expandableItem.location .form-group.disabled > div:before {
      border-bottom: 0px !important; }

.totalRidesContainer {
  max-width: 540px;
  margin: 20px auto; }
  .totalRidesContainer .loading {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    margin-top: -20px; }
  .totalRidesContainer .rides {
    flex: 1 1;
    justify-content: space-between;
    padding: 25px 20px;
    overflow: hidden;
    align-items: center;
    border-radius: 10px 0 0 10px;
    text-transform: uppercase;
    font-size: 16px; }
    @media (max-width: 560px) {
      .totalRidesContainer .rides {
        font-size: 14px;
        padding: 10px; } }
    .totalRidesContainer .rides span {
      font-size: 12px;
      display: block;
      margin-bottom: -10px; }
  .totalRidesContainer .owed {
    padding: 25px 20px;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    flex: 1 1;
    border-radius: 0 10px 10px 0; }
    @media (max-width: 560px) {
      .totalRidesContainer .owed {
        font-size: 12px;
        padding: 10px; } }
    .totalRidesContainer .owed span {
      font-size: 10px; }

.rideTotal, .owedTotal {
  font-weight: bold;
  font-size: 24px !important; }
  .rideTotal span, .owedTotal span {
    font-size: 12px;
    position: relative;
    top: -3px;
    left: -2px;
    font-weight: normal; }

.monthlyTotals {
  max-width: 520px;
  margin: 0 auto;
  padding: 0; }
  .monthlyTotals td {
    padding: 5px 0;
    border: 0;
    width: 25%; }
    .monthlyTotals td:nth-child(2) {
      text-align: right;
      padding-right: 10px; }
    .monthlyTotals td:nth-child(3) {
      padding-left: 10px; }
  .monthlyTotals span {
    font-size: 12px;
    text-transform: uppercase; }
  @media (max-width: 560px) {
    .monthlyTotals .rideTotal, .monthlyTotals .owedTotal {
      width: 10% !important;
      padding-right: 5px !important; } }
  .monthlyTotals .owedTotal {
    text-transform: uppercase;
    text-align: right; }
  .monthlyTotals li {
    align-items: center;
    justify-content: space-between; }
  .monthlyTotals .rides {
    text-transform: uppercase;
    font-size: 16px; }
    .monthlyTotals .rides span {
      font-size: 12px;
      display: block;
      margin-bottom: -10px; }
      @media (max-width: 560px) {
        .monthlyTotals .rides span {
          font-size: 10px; } }
    @media (max-width: 560px) {
      .monthlyTotals .rides strong {
        font-size: 14px; } }

.locationInfo {
  justify-content: center;
  max-width: 520px;
  margin: 20px auto;
  align-items: center; }
  .locationInfo span {
    font-size: 12px;
    text-transform: uppercase; }
  .locationInfo strong {
    font-size: 24px;
    margin: 0 15px; }
    .locationInfo strong span {
      font-weight: normal;
      position: relative;
      top: -4px;
      left: -2px; }

.locationSelectWrapper {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px; }
  .locationSelectWrapper [role="button"] {
    padding-right: 10px;
    font-size: 16px; }
    .locationSelectWrapper [role="button"]:focus {
      background: white; }
  .locationSelectWrapper .locationSelect:after, .locationSelectWrapper .locationSelect:before {
    opacity: 0; }
  .locationSelectWrapper svg {
    top: 4px;
    right: -15px; }

.brand-blue {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAADaCAQAAADuknN+AAABNklEQVR42u3QgQAAAAACoPeXXmGAQqjhVgWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlS8Bup1wDbVgpAiwAAAABJRU5ErkJggg==); }

.reportSelect {
  display: flex;
  flex-direction: column;
  padding: 25px 20px; }
  @media (max-width: 440px) {
    .reportSelect {
      padding: 10px 20px; } }
  .reportSelect .icon {
    display: block;
    width: 50px;
    height: 50px; }
  .reportSelect .flexRow {
    text-transform: uppercase;
    flex-direction: column;
    font-weight: bold;
    align-items: center; }
  .reportSelect p {
    text-align: center;
    max-width: 300px;
    line-height: 18px;
    font-size: 14px;
    margin: 10px auto 0; }
  .reportSelect a {
    -webkit-transition: background .3s;
    transition: background .3s;
    background: white;
    width: 300px;
    margin: 25px auto;
    text-decoration: none;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 35px; }
    @media (max-width: 440px) {
      .reportSelect a {
        width: 100%;
        margin: 15px auto;
        box-sizing: border-box; } }
    .reportSelect a:hover {
      background: #e8e8e8; }

.flexRow.download {
  padding-bottom: 150px;
  margin-top: -100px; }
  @media (max-width: 768px) {
    .flexRow.download button {
      width: 225px; } }

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translateY(610px);
            transform: translateY(610px); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes slideUp {
  from {
    -webkit-transform: translateY(610px);
            transform: translateY(610px); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-61px);
            transform: translateY(-61px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes slideDown {
  from {
    -webkit-transform: translateY(-61px);
            transform: translateY(-61px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes slideRight {
  from {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes slideRight {
  from {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.page-leaderboards .locationSelectWrapper {
  margin-right: 50px; }

@media (max-width: 500px) {
  .page-leaderboards {
    padding-right: 0;
    padding-left: 0; }
    .page-leaderboards .tabsDateRange {
      margin-right: 20px;
      margin-left: 20px; } }

table.leaderboard {
  max-width: 768px;
  margin: 20px auto; }
  table.leaderboard tr {
    -webkit-animation: slideDown .4s;
            animation: slideDown .4s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    display: flex;
    height: 80px;
    align-items: center; }
    @media (max-width: 490px) {
      table.leaderboard tr {
        height: 60px; } }
  table.leaderboard tr:first-child {
    border-top: none;
    -webkit-animation: slideUp .4s;
            animation: slideUp .4s; }
    table.leaderboard tr:first-child .trophy {
      background-image: url(/static/media/trophy-white.386f926f.svg); }
    table.leaderboard tr:first-child td {
      color: white; }
  table.leaderboard tr:last-child td {
    border-bottom: none; }
  table.leaderboard td {
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border: none !important; }
    @media (max-width: 500px) {
      table.leaderboard td {
        font-size: 12px;
        padding: 5px; } }
    table.leaderboard td.rank {
      width: 20px;
      font-size: 28px;
      margin-right: 10px;
      padding-left: 25px; }
      @media (max-width: 500px) {
        table.leaderboard td.rank {
          padding-left: 15px;
          font-size: 16px; } }
    table.leaderboard td.location, table.leaderboard td.group {
      flex: 1 1; }
      @media (max-width: 600px) {
        table.leaderboard td.location, table.leaderboard td.group {
          font-size: 14px; } }
      @media (max-width: 490px) {
        table.leaderboard td.location, table.leaderboard td.group {
          display: none; } }
    table.leaderboard td.startTime {
      flex: 1 1; }
      @media (max-width: 490px) {
        table.leaderboard td.startTime {
          font-size: 12px; } }
    table.leaderboard td.name {
      padding-left: 20px;
      font-size: 16px;
      line-height: 18px;
      flex: 1 1; }
      @media (max-width: 600px) {
        table.leaderboard td.name {
          font-size: 14px; } }
      @media (max-width: 490px) {
        table.leaderboard td.name {
          font-size: 12px;
          padding-left: 10px; } }
    table.leaderboard td.score {
      width: 150px;
      font-size: 28px;
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (max-width: 500px) {
        table.leaderboard td.score {
          padding-right: 25px;
          width: 125px;
          font-size: 18px; } }
      table.leaderboard td.score .count {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-animation: zoom 1s;
                animation: zoom 1s;
        margin-right: 15px;
        margin-top: -1px; }
        table.leaderboard td.score .count span {
          font-size: 12px;
          margin-right: -17px; }
          @media (max-width: 500px) {
            table.leaderboard td.score .count span {
              font-size: 10px; } }
    table.leaderboard td .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px; }
      @media (max-width: 500px) {
        table.leaderboard td .icon {
          position: relative;
          margin-right: 8px; } }

.navbar {
  text-align: center;
  display: flex;
  align-items: center; }
  .navbar .logout {
    position: absolute;
    right: 15px; }
  .navbar .brand {
    display: flex;
    position: absolute;
    left: 15px; }
    .navbar .brand img {
      width: auto;
      height: 30px;
      align-items: center;
      margin-right: 10px; }
      @media (max-width: 500px) {
        .navbar .brand img {
          height: 20px; } }
  .navbar .appTitle {
    font-size: 12px; }
    @media (max-width: 500px) {
      .navbar .appTitle {
        font-size: 10px;
        margin-left: -5px; } }
  .navbar h1 {
    flex: 1 1;
    font-size: 28px;
    position: relative;
    height: 52px;
    box-sizing: border-box;
    left: -50px; }
    @media (max-width: 767px) {
      .navbar h1 {
        font-size: 22px; } }
    @media (max-width: 440px) {
      .navbar h1 {
        font-size: 20px;
        -webkit-transform: translateY(5px);
                transform: translateY(5px); } }

.appBar {
  box-shadow: none !important;
  height: 100px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .appBar {
      height: 90px; } }

.navTabsWrapper {
  margin: 0 auto; }

.tabBar .tabsIndicator {
  display: none; }

@media (max-width: 768px) {
  .tabBar svg {
    margin-top: -10px;
    margin-bottom: -10px;
    height: 20px; } }

.inset {
  background-color: #ebebeb;
  padding: 20px;
  margin: 20px auto;
  border-radius: 6px; }
  .inset .rideSummary {
    border-top: 0px;
    padding-bottom: 0; }
  @media (max-width: 1024px) {
    .inset {
      margin: 20px -20px;
      border-radius: 0px; } }
  .inset h2 {
    margin: 0px; }

.navTabs {
  justify-content: center;
  display: flex;
  left: 0;
  bottom: 0;
  -webkit-transition: left .4s;
  transition: left .4s;
  position: relative; }
  @media (max-width: 766px) {
    .navTabs {
      bottom: 5px; }
      .navTabs.firefox {
        top: -10px; } }
  @media (max-width: 440px) {
    .navTabs.firefox {
      top: -20px; } }
  .navTabs button {
    font-size: 18px;
    text-transform: none;
    display: inline-block;
    padding: 0px 10px;
    flex-shrink: 0; }
    @media (max-width: 768px) {
      .navTabs button {
        font-size: 2vw;
        margin-right: -10px; } }
    @media (max-width: 500px) {
      .navTabs button {
        font-size: 16px;
        padding: 0 5px; } }
  .navTabs.tabless .tabsIndicator {
    display: none; }
  .navTabs .tabsIndicator {
    display: flex;
    height: 10px;
    margin: 0 auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0); }
    @media (max-width: 767px) {
      .navTabs .tabsIndicator {
        height: 15px; } }
    .navTabs .tabsIndicator:after {
      display: inline-block;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white; }

.tabsDateRange {
  display: flex;
  justify-content: center;
  margin-top: -10px; }
  .tabsDateRange .tabs {
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    min-height: inherit !important; }
    @media (max-width: 500px) {
      .tabsDateRange .tabs {
        margin: 0px -20px;
        width: 100%; } }
  .tabsDateRange [role="tablist"] {
    height: 30px; }
  .tabsDateRange [role="tab"] {
    border-left: 1px solid #c8c8c8;
    min-height: inherit !important; }
    .tabsDateRange [role="tab"]:first-child {
      border: none; }
    @media (max-width: 500px) {
      .tabsDateRange [role="tab"] {
        width: 25%;
        font-size: 2.53vw; } }
  .tabsDateRange span {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .tabsDateRange .indicator {
    display: none; }

.tabsPage {
  display: flex;
  justify-content: center;
  max-width: 768px;
  margin: 20px auto; }
  .tabsPage [role="tab"] {
    flex: 1 1; }
  .tabsPage .MuiTabs-flexContainer-38 {
    width: 100%; }
  @media (max-width: 500px) {
    .tabsPage {
      margin-top: 10px; }
      .tabsPage [role="tab"] {
        font-size: 10px; } }
  .tabsPage .indicator {
    display: none; }

.tabsSecondary {
  color: #333;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto 15px auto;
  border-radius: 100px;
  min-height: 0px !important; }
  .tabsSecondary [role="tablist"] {
    overflow-x: visible !important; }
  .tabsSecondary [aria-selected="true"] {
    color: white;
    opacity: 1; }
  .tabsSecondary [type="button"] {
    opacity: .8;
    width: 80px;
    height: 40px;
    min-height: 0;
    -webkit-transition: .4s;
    transition: .4s; }
    @media (max-width: 475px) {
      .tabsSecondary [type="button"] {
        font-size: 10px; } }
  .tabsSecondary .tabsIndicator {
    height: 100%;
    z-index: -1; }

button.announcements {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  cursor: pointer;
  right: 50px;
  top: 15px;
  z-index: 1100; }
  button.announcements:focus {
    outline: 0; }
  button.announcements .count {
    display: block;
    line-height: 14px;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    font-size: 8px;
    color: white;
    position: relative;
    left: 13px;
    top: -4px; }

@-webkit-keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

body {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif; }

#root .button {
  border-radius: 50px;
  text-transform: none;
  box-shadow: none;
  font-weight: bold;
  line-height: 18px;
  padding: 2px;
  height: 50px;
  min-width: 40px;
  font-family: "Cairo", sans-serif; }
  #root .button:disabled {
    opacity: .5; }
  #root .button span {
    font-size: 18px; }
  @media (max-width: 767px) {
    #root .button {
      width: 150px;
      margin: 0 auto;
      font-size: 18px; } }

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px); }
  .loading .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .loading .circle.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s; }

.snackbar {
  z-index: 5 !important;
  bottom: 0px !important; }
  .snackbar > div {
    padding: 10px 15px; }
  .snackbar.therapist {
    bottom: 75px !important; }
  @media (min-width: 540px) {
    .snackbar {
      margin: 0 25px; } }
  @media (min-width: 768px) {
    .snackbar.therapist {
      bottom: 110px !important; } }
  .snackbar [role="alertdialog"] {
    font-family: "Cairo", sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #333;
    background-color: #fff; }
    .snackbar [role="alertdialog"] button span {
      font-weight: bold; }
    @media (max-width: 768px) {
      .snackbar [role="alertdialog"] {
        font-size: 12px;
        padding: 6px 10px;
        margin-right: 10px;
        margin-left: 10px; } }

.loadingWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .loadingWrapper p {
    font-size: 12px; }

.page.login {
  position: relative;
  z-index: 4;
  padding-top: 0;
  height: 100vh;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .page.login .brand {
    display: block;
    margin: -25px auto 25px;
    width: 300px; }
  .page.login footer {
    margin-top: 20px;
    text-align: center; }

