

.evaluationComplete {
  ol {
    list-style-type: none;
    text-transform: capitalize;
    font-size: 18px;
    li {
      justify-content: space-between;
      width: 300px;
      &:last-child {
        font-size: 24px;
        margin-top: 25px;
        margin-bottom: 50px;
      }
    }
  }

}

.message {
  height: 50px;
  padding-bottom: 20px;
  p {
    margin: 0 auto;
    @media (max-width: 500px) {
      max-width: 90vw;
    }
  }
}

.coreEvaluation {

  h2 {
    margin-bottom: 30px;
  }

  .currentAngle {
    font-size: 18px;
    text-align: center;
    margin-top: -35px;
    margin-bottom: 20px;
    .icon.angle.white {
      margin: 0px 5px 0px 10px;
      background-image: url('../img/i-angle-white.svg');
      width: 15px;
      height: 15px;
      position: relative;
      top: 1px;
    }
  }
  @media (max-width: 767px) {
    button {
      width: 200px;
      padding: 0px;
    }
  }

  @media (max-width: 480px) {
    button {
      width: 160px;
      span {
        font-size: 14px;
      }
    }
  }

  .pauseText {
    margin: 0px auto;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin-top: -32px;
    font-size: 14px;
    text-transform: uppercase;
  }

}

.evaluationWrapper {
  margin-bottom: 40px;

  @media (max-width: 560px) {
    transform: scale(.8);
    margin-bottom: -30px;
    margin-top: -60px;
  }
  @media (max-width: 430px) {
    transform: scale(.6);
    margin-bottom: -90px;
    margin-top: -100px;
  }

  .angleWrapper {
    position: absolute;
    z-index: 15;
    transform: rotate(-45deg);
  }

  .angle {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;
    font-size: 36px;
    &:after {
      content: '°';
      display: block;
      font-size: 18px;
      position: absolute;
      right: 8px;
      top: 15px;

    }
  }

  .circle {
    border-radius: 100%;
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: rotate(45deg);
    position: relative;
    .row {
      width: 100%;
      flex: 1;
    }

    .flexRow {
      &.topRight {
        .quadrant:first-child {
          .inner {
            margin-right: -40px;
            header {
              margin-top: 20px;
            }  
          }          
        }
        .quadrant:last-child {
          .inner {
            margin-left: -40px;
            header {
              width: 125px;
              position: absolute;
              transform: rotate(90deg);
              right: -105px;
              top: 32px;
            }
            span {
              margin-top: 0;
              margin-left: -80px;
            }  
          }
        }
      }
      &.bottomLeft {
        .quadrant:last-child {
          .inner {
            margin-left: -35px;
            transform: rotate(-45deg) translateY(20px);
            header {
              margin-bottom: -120px;
            }  
          }
        }
        .quadrant:first-child {
          .inner {
            margin-right: -35px;
            header {
              position: absolute;
              transform: rotate(-90deg);
              left: -100px;
              top: 32px;
              width: 125px;
            }
            span {
              margin-top: 0;
              margin-right: -80px;
            }  
          }
        }
      }
    }

    .quadrant {
      z-index: -1;
      position: relative;
      height: 250px;
      width: 290px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }

      .inner {
        text-align: center;
        transform: rotate(-45deg);
        text-transform: uppercase;
        header {
          font-weight: bold;
        }
        span {
          font-weight: 100;
          font-size: 48px;
          display: block;
        }
      }
    }
  }
}

